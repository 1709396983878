import React from "react"
import Boxes from "./crumbs/boxes"
import PaginationButton from "./crumbs/paginationButton"
import LayoutSite from "./layouts/layoutSite"
import PageSeo from "../components/crumbs/pageSeo"
import Ads from "./ads"

export default function SearchCategories({ pageContext, data }) {
    const title = pageContext.category
    const prev = pageContext.prev
    const next = pageContext.next
    const refreshUrl = pageContext.slug
    
    const news = data.articles.edges.map(item => <Boxes box={1} article={item.node} />)
    
    return (
        <>
            <PageSeo
                title="Minden Percben | Érdekes"
                description="Minden amiről érdemes tudnia hazánkban és a nagyvilágban. Mert minden perc érdekes!"
                keywords="hírek, érdekességek"
                canonical="https://www.mindenpercben.hu"
                canonicalMobile = "https://m.mindenpercben.hu/"        
            />
            <LayoutSite
                refreshUrl={refreshUrl}
                content={
                    <>
                        <div className="category category_10" style={{ margin: `0px 0px 10px 0px` }}>
                            <div className="container-fluid">
                                <span className="third">{ title }</span>
                            </div>
                        </div>
                        { news[0] }
                        { news.slice(1, news.length) }
                        <PaginationButton prev={prev} next={next} />
                    </>
                }
            />
        </>
    )
}

export const pageQuery = graphql`
    query GetArticlesByCategoriesPaginated($skip: Int!, $limit: Int!, $category: String!) {
        articles: allStrapiArticle(skip: $skip, limit: $limit, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: $category}}}) {
            edges {
                node {
                    title
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    category {
                        name
                    }
                }
            }
        }
    }
`